"use client";

import React, { useState, useRef } from 'react';
import dynamic from 'next/dynamic'; 
import styles from './styles/home.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad, faSearch } from '@fortawesome/free-solid-svg-icons';

const GameSlider = dynamic(() => import('./components/GameSlider'));
const Games = dynamic(() => import('./components/Games'));
const NewsSlider = dynamic(() => import('./components/NewsSlider'));

export default function Home({ sliderData, gamesData, newsData }) {
  const [query, setQuery] = useState('');
  const debounceTimeout = useRef(null);

  function searchGame(event) {
    const userInput = event.target.value;
    setQuery(userInput);

    clearTimeout(debounceTimeout.current);

    debounceTimeout.current = setTimeout(() => {
      setQuery(userInput);
    }, 30);
  }

  return (
    <div>
      <GameSlider sliderData={sliderData} />
      <NewsSlider newsData={newsData} />

      <div className="games-more">
        <div className={styles.gamesInfoContainer}>
          <div className={styles.gamesInfo}>
            <FontAwesomeIcon
              icon={faGamepad}
              className={styles.iGreenL}
              style={{ width: '15px', height: '15px' }}
            />
            <h2 className={styles.iF13}>Игры</h2>
          </div>

          <div>
            <div className={styles.searchForm} id="search">
              <input
                type="text"
                placeholder="Поиск..."
                id="searchText"
                className={styles.searchInput}
                name="searchKeyword"
                onChange={searchGame}
              />
              <FontAwesomeIcon icon={faSearch} style={{ width: '15px', height: '15px' }} />
            </div>
          </div>
        </div>

        <div className="filters-container"></div>
      </div>

      <Games initialGames={gamesData} searchQuery={query} />
    </div>
  );
}